import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Container } from '../components/styled-components';

const Title = styled.h1`
  margin: 30px 0;
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: 900;
`;

const Description = styled.p`
  font-size: 1.25rem;
  font-weight: 300;
`;

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not Found" />

      <Container>
        <Title>Not Found</Title>
        <Description>
          You just hit a route that doesn&#39;t exist... the sadness.
        </Description>
      </Container>
    </Layout>
  );
}
